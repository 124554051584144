import React from 'react';

import {
  AiOutlineLink,
  AiFillLinkedin,
  AiFillInstagram,
  AiFillFacebook,
} from 'react-icons/ai';

import { Wrapper } from './Social.styles';

const Social = (props) => {
  const {
    data: { site, linked, insta, fb },
  } = props;

  return (
    <Wrapper>
      {site && (
        <li>
          <a href={site}>
            <AiOutlineLink />
          </a>
        </li>
      )}
      {fb && (
        <li>
          <a href={fb}>
            <AiFillFacebook />
          </a>
        </li>
      )}
      {insta && (
        <li>
          <a href={insta}>
            <AiFillInstagram />
          </a>
        </li>
      )}
      {linked && (
        <li>
          <a href={linked}>
            <AiFillLinkedin />
          </a>
        </li>
      )}
    </Wrapper>
  );
};

export default Social;
