import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import HTMLReactParser from 'html-react-parser';

import Social from 'components/social';

import { Wrapper } from './PartnerCard.styles';

import { isOdd } from 'utils/mixins';

const PartnerCard = (props) => {
  const {
    index,
    data: {
      title,
      partners: {
        partnerBody,
        partnerLogo,
        partnerImage,
        partnerType,
        partnerSite,
        partnerLinkedin,
        partnerInstagram,
        partnerFacebook,
        locais,
      },
    },
  } = props;

  return (
    <Wrapper isOdd={isOdd(index)}>
      <div className="partner__content">
        <div className="partner__content-header">
          <div>
            <span>{partnerType}</span>
            <h2>{title}</h2>
          </div>
          <img src={partnerLogo?.mediaItemUrl} alt={partnerLogo?.altText} />
        </div>
        <div className="partner__content-body">
          {partnerBody && HTMLReactParser(partnerBody)}
        </div>
        {locais?.length && (
          <ul className="partner__content-list">
            {locais?.map((e) => (
              <li>• {e.title}</li>
            ))}
          </ul>
        )}
        <div className="partner__content-footer">
          <Social
            data={{
              site: partnerSite,
              linked: partnerLinkedin,
              insta: partnerInstagram,
              fb: partnerFacebook,
            }}
          />
        </div>
      </div>

      {partnerImage && (
        <div className="partner__image">
          <GatsbyImage
            objectFit="cover"
            loading="eager"
            image={partnerImage?.localFile?.childImageSharp?.gatsbyImageData}
            alt={partnerImage?.altText}
          />
        </div>
      )}
    </Wrapper>
  );
};

export default PartnerCard;
