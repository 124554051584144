import React from 'react';

import { graphql } from 'gatsby';

import Hero from 'components/hero';
import CTA from 'components/cta';
import PartnerCard from 'components/partner-card';

const ParceirosTemplate = (props) => {
  // PROPS
  const {
    data: {
      page: {
        hero: { title, text, subtitle },
        cta,
        partners: { partners },
      },
    },
  } = props;
  // PROPS

  return (
    <main className="main">
      <Hero data={{ title, subtitle, text }} center />
      <div className="container container--xsmall">
        {partners.map((card, index) => {
          return <PartnerCard data={card} index={index} key={index} />;
        })}
      </div>
      <CTA data={cta} />
    </main>
  );
};

export const query = graphql`
  query Parceiros($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      hero {
        title
        text
        subtitle
      }
      partners {
        partners {
          ... on WpParceiro {
            title
            partners {
              locais {
                ... on WpLocal {
                  title
                }
              }
              partnerBody
              partnerType
              partnerSite
              partnerLinkedin
              partnerInstagram
              partnerFacebook
              partnerLogo {
                altText
                mediaItemUrl
              }
              partnerImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
      cta {
        ctaSubtitle
        ctaTitle
        type
        ctaCta {
          url
          title
          target
        }
        ctaImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;

export default ParceirosTemplate;
