import styled from 'styled-components';

import { container, space, fontSize } from 'theme/sizes';
import { neutral } from 'theme/colors';
import { toRem, breakpoints } from 'utils/mixins';

export const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  background-color: ${neutral.neu01};
  flex-direction: column-reverse;

  &:not(:last-child) {
    margin-bottom: ${space.l};
  }

  .partner__image {
    position: relative;
    display: block;
    width: 100%;

    .gatsby-image-wrapper {
      height: 100%;
      min-height: 300px;
    }
  }

  .partner__content {
    padding: ${space.l};
    width: 100%;

    &-header {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      margin-bottom: ${space.m};

      h2 {
        margin: 0;
        margin-top: ${space.xxs};
        line-height: 1;
      }

      img {
        top: 0;
        right: 0;
        position: relative;
        max-height: ${space.l};
        max-width: ${space.xl};
      }
    }

    &-body {
      margin-bottom: ${space.m};
    }

    &-list {
      li {
        margin: 0;
        display: inline-block;
        font-size: ${fontSize.xxs};
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 0.6px;
        line-height: ${toRem(18)};
      }
    }

    &-footer {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      width: 100%;
      padding-top: ${space.m} ;
    }
  }

  @media ${breakpoints.tablet} {
    flex-direction: ${(props) => (props.isOdd ? 'row' : 'row-reverse')};

    .partner__image {
      width: 40%;
    }

    .partner__content {
      width: 60%;

      &-header {
        h2 {
          margin-top: 0;
        }

        img {
          position: relative;
          max-height: ${space.m};
          max-width: ${space.xxl};
        }
      }
    }
  }

  @media ${breakpoints.laptop} {
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
      0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  }
`;
