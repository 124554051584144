import styled from 'styled-components';

import { container, space, fontSize } from 'theme/sizes';
import { neutral } from 'theme/colors';
import { toRem, breakpoints } from 'utils/mixins';

export const Wrapper = styled.ul`
  display: flex;

  li {
    &:not(:last-child) {
      margin-right: ${space.xxs};
    }

    a {
      color: ${neutral.neu10};
      font-size: ${toRem(22)};
    }
  }
`;
